export default {

	// api请求地址
	// baseUrl: '{{$baseUrl}}',
	baseUrl: 'https://api.guohongjl.com',

	// 图片域名
	// imgDomain: '{{$imgDomain}}',
	imgDomain: 'https://api.guohongjl.com',

	// 腾讯地图key
	// mpKey: '{{$mpKey}}',
	mpKey: 'GF5BZ-OIL6U-5MPVT-2YQOB-6NCHZ-GHBJV',

	// 客服
	// webSocket: '{{$webSocket}}',
	webSocket: 'wss://api.guohongjl.com/wss',

	// api安全
	// apiSecurity: "{{$apiSecurity}}",
	apiSecurity: true,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	// publicKey: `{{$publicKey}}`,
	publicKey: `-----BEGIN PRIVATE KEY-----
MIIJQwIBADANBgkqhkiG9w0BAQEFAASCCS0wggkpAgEAAoICAQCfuOzA7M28ul6f
ZzSOGQVy36yQOic9rCaAxqdhj4Jm4r3+FmQrrklrcfwTGvMFLTSf96tsI3ICyH+W
0z83peO7neOfuYKARIpTXdA+0CTofBA3zfMMkzPs+7U8vyjE3Rekh0AdCfcEB7+a
ngYwxxgQnz343FEQq4AJaZULvqrvkIGxFFQOtIujjjpLffJFnRuAZgv/RjFqssYh
1z8gX5jjjP6WhUsfWPX4lhAoYmVEVweT4zeNk0XSEukJmFqcVhcAe3MFNCXLp1b5
qepNytMbt2JlGj9l3LlmaPc8ZLdoxDpS0yO87IE1k08vdoBJbuMIwnVcTjfFJdyq
6qsxw0EcbqoJi1IhqdnfYz8glOB3diV2Lut208CzgvSlaCFFgRfy21n67cv/Hwr8
r/rAu7rb4Po6Nf4p6NU4Iwxk0Sqw0kYg7mc7jiObE9+zEuK50ltX7BSLrM3ilXoV
YAlwD29xZyH2TSKlxHceBrvRETsSUHk/zaUm0e9E5tXfHcREjHrYEx6XmX+iysRM
G2P7xbfSf9PjpU3zLyrnv+V432wrO0SJfsNhqt8Cy/YDLZLDW0sQf1QlLYCuY4mz
gZQhigsBG5Y3X32Kz3f3ukQX62Pyeytp038Y2NWFeXwb7dMhTevK0JWCL4iWVXEB
YNWfbB0fhmCzqBIGKS91NYDlMsYO3QIDAQABAoICAQCL51JR5tqLTn/lhmQNd7ND
HNMtfhKzPaB4OgmRNkAV2NRsxLY3YLFk0PHo1jk6No+a8zkPPrj14SOJPD5qgv9I
bpNcbAT4T5EOVuU6r900Wr9l8hrL0ACyuwPUUujmD3dPIT0ycnEQ8ayORY7MmQfm
P2XsHydOv7omBHqOUBOwRdakAldijhimScWcLJTTDztwq3IkxxowCKnuKHld5P6p
iu3RcK4NpkHF3cQHa7CiPM0hZ+xYMUD+Mpw3UGFGAU6imH92dmgIy+ouQ/w86cUi
yO4B+wy/L6iiIJ03JE1XElK4C8OJD2xaPk8lFtpl+CAboyFjmgvVv0Vb3LPZodrc
J3v6OwgbClwJrM1hN5/uIgrIHL9tp1r1kIYDDvHB9tud7Wnf92Nzlsk1hD2RhaYN
btFYHfhsymf20w0Ll190zeyuw+7f+U32UzXgiJiuJYtKgP5n+AP70yWQSNMSakro
ftE6CXzIMO1kQKYhFQqJrIuqokJf5b4IvjjYaD3qVmzibyLjvjCJBNbU1XZH1iZC
bbUURs9gUoQaAQ+mR2k893QITmn0A6r1yPe0p6HF4/IkCusH0eM9OrIb0rK3oFuP
cbtT14nc/i4CIA0smIuUdAlQJbVhOZmJT04dr4xqpRm/FiQhRwP8wzhIBjQUoJz8
3pPYpuIHA13bsMRHYV0kbQKCAQEAzD2/OPVGi+jjlxI9CsXP4PiZh/mLad7QHi+E
CX2ukHS342tkjkWhx5tgkaY0QaGUlCIoMsCaOzcjTXxg4lua5Hk4vcaUwMqyGb3Z
q2exihzgPpzS7qmJQe7HwBj/aNbvCnjHYigoaBoN/D9GZynSV+OMDqFVJuc8OLfP
A7mM0JZmGnTXZ1BK4RHg5hJGcRPqVa6FvViJCMc1ml4jjirLNt3nClJG/Z2Bic+W
fXOCW7cOB5kOYr/RNmxWEfoGuk1vcCBtfmB3DQgNIBEVtDWMUNhTxQZrdQVozbJG
a8uQVxhGyR1bmDdk9f5iY6KkuY1U9m9mFyMyOVVdIVxSezk0RwKCAQEAyDL+0ldV
rlGTvURQkPrmOLhsL0DA6kY5TfdAxP7IfwKm8Vm/nSLSwp4U1PGURXE/QFO71utM
EV174FeDumMFcLZqGR5SZmmWAIfgMs20WSFeZ3QIpZ1eRFXbr6qbZNMxewAYjxu2
90tZOxSWI1HOt1TMEsK3yfH6s+qrF7wewuABDj7ukOU72zH3tHAabTCKWXIxAOJz
u+cRNJlW4ecbmIdwpzVExIMzbAAuZCEsczWgwSFNistbU/plOSF2uOlJa1+Ab417
Ziovh1xeNnL4EL7j6vUstJgZvej75IgNwNLq1eMesnGA09wDJxUrAlOm9ZA9c1hl
BZssk4+frrapuwKCAQAT2ThZL7UAacycZOBbyKeQJHi/NyguTMIK/PEm/vjU/xLT
3h7ymJ/FRztWTEGt0yceUkd3zzHt6UjcAedkeCSQaZtzDfZs2gX+7G1X9AbT9kRw
sUrqeL1nE+6Do6pGpQSsDtrJlj9/BnEvf3K3GeBgPDPjRBiDZFt845gRs5mUt9kK
XyqD1tDAGL2zfjksShQu6XwDz7PZj4qNCvMHoO8I0P3gCejuQr1RCESGwo/7m8mY
QW6deCi70kF3E8ZNmrLmwbACZRuMv6iO4joqvj15qdxSPHJ69+CchOPFHA5V+H0A
lILPQyrjoSospqS9h5enL2JLg1chLUOUt2UopoGHAoIBAQCIVJnM+WZCiBD4oU3P
/NvMImKhpxH4N4wMeOSlge9c9pxi3MAsrKsjlu0OudhDlcQB9UOELsWlSajy9AWj
Zo0s3TvRESIB1cMtZ1oYnpZC4ANepdjBlzG7jVBGBwM9HMikP0N6KOBm9Ou13OYE
9U+07szttaXcoqkb3iFWz2ePQ2XUoqxpBYHGWfeZ64FDe/uqv6V5ObygZ9ECpa1R
tsTHEOx5IUBMJiBdWp48145iglGPUzNv4d22iVxnFE+5yjuSH/3heVJgFzg4kIEj
yRT+qk+hEAa6kJ1vAvqN/MyI09jRgJHF6J9XJosLEVIxim+259vA8aARukMX1YVj
P8bDAoIBAEeNFuMctrFCaT1Ixc6OBfkn2HQX3EZ9uoMpFiaxPDZaYWWi3k0Km5dz
OGkeHtihFvuKoaTKRwntdTWUU+wQ/yFfCVZUPTsZ2f4vMh40YFgyCy3++01Rg0Bf
16LGXZqKXinmJeInGD62D25t3CixRfTaIIY3juaWEJkSuADXopf9pHmSkob+IXXS
e13XXH/qdi+dco5LFvVS/P6s0H5iLmyQ9HzFYClsquPa+EvXaKXZOoec5QJeDrZC
nid8NGc5NVkd59iUeVdDv8PZRCHkMsDihON+yIuULLANpl2xdUnYbir8UPxWojwD
J3MCAU8qJVxw4SDdeQK/NbfyLFwf2A0=
-----END PRIVATE KEY-----`
}
